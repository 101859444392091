import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["InitFont"] */ "/app/src/components/font/InitFont.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/src/features/authentication/context/Session.context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Cookies"] */ "/app/src/features/cookies/components/Cookies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GeolocationSetter"] */ "/app/src/features/geolocation/components/geolocation-setter/GeolocationSetter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GTMInit"] */ "/app/src/features/google-tag-manager/components/GoogleTagManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageStoreProvider"] */ "/app/src/features/site-language/store/language-store-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/global.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/forms/styles/form.scss");
