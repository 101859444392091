'use client';

import {
    Noto_Sans_KR,
    Noto_Sans_SC,
    Noto_Sans_TC,
    Noto_Sans,
    DM_Serif_Display,
    Poppins,
} from 'next/font/google';
import { useParams } from 'next/navigation';
import { useEffect } from 'react';

import {
    defaultLanguage,
    defaultLanguages,
} from '@/features/site-language/helpers/defaultLanguages';

export const noto_sans_kr = Noto_Sans_KR({
    subsets: ['latin'],
    weight: ['400', '700'],
    variable: '--noto-sans',
});
export const noto_sans_sc = Noto_Sans_SC({
    subsets: ['latin'],
    weight: ['400', '700'],
    variable: '--noto-sans',
});
export const noto_sans_tc = Noto_Sans_TC({
    subsets: ['latin'],
    weight: ['400', '700'],
    variable: '--noto-sans',
});
export const noto_sans = Noto_Sans({
    subsets: ['latin'],
    weight: ['400', '700'],
    variable: '--noto-sans',
});
export const dm_serif_display = DM_Serif_Display({
    subsets: ['latin'],
    weight: ['400'],
    style: ['normal', 'italic'],
    variable: '--dm-serif-display',
});
export const poppins = Poppins({
    subsets: ['latin'],
    weight: ['500'],
    style: ['normal'],
    variable: '--poppins',
});

export const InitFont = () => {
    const { lang } = useParams();

    const language =
        defaultLanguages.find((l) => l.path === `/${lang}`)?.iso || defaultLanguage.iso;

    const primaryFont =
        language === 'ko-KR'
            ? noto_sans_kr
            : language === 'zh-Hans-CN'
              ? noto_sans_sc
              : language === 'zh-Hant-TW'
                ? noto_sans_tc
                : noto_sans;

    useEffect(() => {
        document.body.classList.add(
            primaryFont.variable,
            dm_serif_display.variable,
            poppins.variable
        );

        return () => {
            document.body.classList.remove(
                primaryFont.variable,
                dm_serif_display.variable,
                poppins.variable
            );
        };
    }, [primaryFont]);

    useEffect(() => {
        document.head.setAttribute('lang', language);
        document.head.setAttribute('dir', language.startsWith('ar') ? 'rtl' : 'ltr');
    }, [language]);

    return <></>;
};
