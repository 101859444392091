'use client';

import { useEffect } from 'react';

import { logError } from '@/features/error-tracking/helpers/error-handling';

import { useGeolocation } from '../../stores/geolocation.store';

export const GeolocationSetter = ({ apiKey }: { apiKey: string }) => {
    const { ip_address, setGeolocation } = useGeolocation((state) => state);

    useEffect(() => {
        if (!apiKey || !setGeolocation || ip_address !== '') return;

        const fetchGeolocation = async () => {
            const data = await fetch(`https://ipgeolocation.abstractapi.com/v1/?api_key=${apiKey}`)
                .then((response) => response.json())
                .catch((error) =>
                    logError('AbstractAPI Error: Unable to get Geolocation data:', error)
                );

            const newState = {
                ip_address: data.ip_address,
                continent_code: data.continent_code,
                country: data.country,
                country_code: data.country_code,
                country_is_eu: data.country_is_eu,
                timezone: data.timezone,
            };

            setGeolocation(newState);
        };

        fetchGeolocation();
    }, [apiKey, ip_address, setGeolocation]);

    return null;
};
