import { create } from 'zustand';

import { CookieStoreType } from '../types';

const initialState = {
    siteCookies: [],
};

export const useCookies = create<CookieStoreType>((set) => ({
    ...initialState,

    setCookies: (cookie: any) => set({ siteCookies: cookie }),
}));
