import { create } from 'zustand';

export type GeolocationType = {
    ip_address: string;
    continent_code: string;
    country: string;
    country_code: string;
    country_is_eu: boolean;
    timezone: {
        name: string;
        abbreviation: string;
        gmt_offset: string;
        current_time: string;
        is_dst: boolean;
    };
};

type GeolocationStore = {
    setGeolocation: (data: GeolocationType) => void;
} & GeolocationType;

const initialState = {
    ip_address: '',
    continent_code: '',
    country: '',
    country_code: '',
    country_is_eu: false,
    timezone: {
        name: '',
        abbreviation: '',
        gmt_offset: '',
        current_time: '',
        is_dst: false,
    },
};

export const useGeolocation = create<GeolocationStore>((set) => ({
    ...initialState,
    setGeolocation: (data) => set(data),
}));
