'use client';

import { useEffect } from 'react';

import { useCookies } from '../store/cookie.store';

const keys: { [key: string]: string } = {
    C0001: 'necessary',
    C0002: 'performance',
    C0003: 'functional',
    C0004: 'targeting',
    C0005: 'social',
};

export const Cookies = () => {
    const { setCookies } = useCookies();

    useEffect(() => {
        const activeGroups = () => {
            const accepted =
                (window as any).OnetrustActiveGroups?.split(',').filter(
                    (group: any) => group !== ''
                ) || [];

            const groups = accepted.reduce(
                (array: Array<any>, group: any) =>
                    (!array.includes(keys[group]) && [...array, keys[group]]) || array,
                ['necessary']
            );

            return groups;
        };

        const updateCookies = () => {
            const consentedGroups = activeGroups();
            setCookies(consentedGroups);
        };

        window.addEventListener('oneTrustChanged', updateCookies);
        return () => window.removeEventListener('oneTrustChanged', updateCookies);
    }, [setCookies]);

    return <></>;
};
